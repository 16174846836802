import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { CognitoUser } from "@aws-amplify/auth";
import invariant from "invariant";
import {
  useApolloClient,
  useLazyQuery,
  useQuery,
  useSubscription,
} from "@apollo/client";
import {
  ProductSubscriptionEvent,
  PublicSyndication,
  User,
  ActiveSubscription,
} from "../graphql/schema";
import {
  InvoiceConnection,
  BillingSubscriptionConnection,
} from "../graphql/schema/payments";
import {
  GET_MY_DETAILS,
  GET_TARIFF_PLANS_INFO,
  GetMyDetailsData,
  INVOICE_SUBSCRIPTION,
  PRODUCT_SUBSCRIPTION_EVENTS,
  USER_SUBSCRIPTION_WEBHOOK,
} from "../graphql/queries";
import {
  GET_MY_INVOICES,
  GetMyInvoicesData,
  GET_MY_SUBSCRIPTIONS,
  GetMySubscriptionsData,
  GET_ACTIVE_SUBSCRIPTION,
  GetActiveSubscription,
  GET_RENEW_SUBSCRIPTION_LINK,
  GET_INVOICES_NEW,
} from "../graphql/queries/payments";
import { DEFAULT_PAGE_SIZE } from "./executions/ExecutionContext";
import { logEvent, setAnonIdentity, setIdentity } from "../helpers/analytics";
import {
  CognitoLoginState,
  getCurrentAuthenticatedUser,
  hasCognitoIdentity,
} from "../helpers/cognitoUtils";
import { useAlertContext } from "contexts/AlertContext";
import { useTranslation } from "react-i18next";
import { externalRedirect } from "helpers/externalRedirect";
import { rebillyErrorsMapper } from "helpers/rebilly-errors-mapper";
import { GUEST_TAB } from "helpers/navigation";
import { ORDER_PROCESSING_TIMER_KEY } from "contexts/BillingPageContext";

export enum UserPermissions {
  READ_MARKET_DATA = "READ_MARKET_DATA",
  READ_DOCUMENTATION = "READ_DOCUMENTATION",
  STRATEGY_LISTENING = "STRATEGY_LISTENING",
  USE_STOP_LOSS = "USE_STOP_LOSS",
  USE_TAKE_PROFIT = "USE_TAKE_PROFIT",
  USE_MINIMUM_PROFIT = "USE_MINIMUM_PROFIT",
  DOWNLOAD_TRADES = "DOWNLOAD_TRADES",
}

interface IUserContext {
  user: User | undefined;
  invoices: InvoiceConnection | undefined;
  subscriptions: BillingSubscriptionConnection | undefined;
  subscribedSyndications: PublicSyndication[];
  permissions: User["permissions"];
  tierParameters: User["tierParameters"];
  isGlobalAdmin: boolean;
  isTester: boolean;
  isLoading: boolean;
  isGuest: boolean;
  isLoggedIn: boolean;
  refetch: () => void;
  refetchSubscriptions: () => void;
  refetchInvoices: () => void;
  subscriptionEvent?: ProductSubscriptionEvent;
  fetchMoreInvoices: any;
  fetchMoreSubscriptions: any;
  loginModalState: CognitoLoginState | undefined;
  setLoginModalState: (state: CognitoLoginState | undefined) => void;
  setHasIdentity: (hasIdentity: boolean) => void;
  // New payment flow
  activeSubscription: ActiveSubscription | null;
  isActiveSubscriptionLoading: boolean;
  refetchActiveSubscription: () => void;
  isActiveSubscriptionExpired: boolean;
  isRenewSubscriptionLoading: boolean;
  handleRenewSubscription: () => void;
  isActiveSubscriptionFree: boolean;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

export function useUserContext() {
  const context = useContext(UserContext);
  invariant(
    context != null,
    "Component is not a child of UserContext provider",
  );
  return context;
}

// Use for testing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockUser = {
  me: {
    id: "123",
    firstName: "Francisco",
    lastName: "Sanchez",
    email: "fmsanchez001@gmail.com",
    termsAccepted: true,
    welcomed: true,
    permissions: [],
    registrationDate: "2021-01-01",
    companyName: "My comp",
    outstandingInvoiceCount: 0,
  },
};

const GUEST_USER = {
  id: "",
  firstName: "Guest",
  lastName: "",
  email: "",
  termsAccepted: true,
  welcomed: true,
  permissions: [],
};

const defaultTierParameters = {
  concurrentBatchTests: 0,
  paperTradesCount: 0,
  batchTestMaxSizeAccuracy: 0,
  batchTestMaxSmallCandlesAmount: 0,
  batchTestMaxUsualCandlesAmount: 0,
};

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const client = useApolloClient();
  const { addError, addWarning } = useAlertContext();
  const { t } = useTranslation();

  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  // to show the login modal if on the terminal and not logged in
  const [loginModalState, setLoginModalState] = useState<CognitoLoginState>();
  const [hasIdentity, setHasIdentity] = useState(false);

  const { data, loading, error, refetch } = useQuery<GetMyDetailsData>(
    GET_MY_DETAILS,
    {
      skip:
        window.location.pathname.startsWith(`/${GUEST_TAB}`) || !hasIdentity,
    },
  );

  const {
    data: invoicesData,
    refetch: refetchInvoices,
    fetchMore: fetchMoreInvoices,
  } = useQuery<GetMyInvoicesData>(GET_MY_INVOICES, {
    variables: {
      first: DEFAULT_PAGE_SIZE,
    },
    skip: !data?.me,
  });

  const {
    data: subscriptionsData,
    refetch: refetchSubscriptions,
    fetchMore: fetchMoreSubscriptions,
  } = useQuery<GetMySubscriptionsData>(GET_MY_SUBSCRIPTIONS, {
    variables: {
      first: DEFAULT_PAGE_SIZE,
    },
    skip: !data?.me,
  });

  const {
    data: activeSubscriptionData,
    loading: isActiveSubscriptionLoading,
    refetch: refetchActiveSubscription,
  } = useQuery<GetActiveSubscription>(GET_ACTIVE_SUBSCRIPTION, {
    fetchPolicy: "network-only",
  });

  const { data: userSubscriptionEventData } = useSubscription(
    USER_SUBSCRIPTION_WEBHOOK,
  );

  const refetchUserData = useCallback(() => {
    client.refetchQueries({
      include: [
        GET_TARIFF_PLANS_INFO,
        GET_INVOICES_NEW,
        GET_ACTIVE_SUBSCRIPTION,
      ],
    });
  }, [client]);

  const invoices = useMemo(() => invoicesData?.me?.invoices, [invoicesData]);
  const subscriptions = useMemo(
    () => subscriptionsData?.me?.subscriptions,
    [subscriptionsData],
  );
  const subscribedSyndications = useMemo(
    () =>
      subscriptions?.edges
        ?.filter(
          (sub) =>
            sub.node.statusDetails.mayUseEntitlements &&
            !!sub.node.product.entitlements.length &&
            sub.node.product.entitlements[0].syndication,
        ) // TODO: Eliminate use of non-null assertion
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((sub) => sub.node.product.entitlements[0].syndication!!) ?? [],
    [subscriptions],
  );

  const activeSubscription = useMemo(
    () => activeSubscriptionData?.activeSubscription || null,
    [activeSubscriptionData],
  );

  const [isGuest, setIsGuest] = useState(false);

  const { data: invoiceEventData } = useSubscription(INVOICE_SUBSCRIPTION, {
    skip: !data?.me,
  });
  const { data: subscriptionEventData } = useSubscription(
    PRODUCT_SUBSCRIPTION_EVENTS,
    { skip: !data?.me },
  );

  const [
    getRenewRedirectionLink,
    {
      data: { paymentURL: paymentUrl = null } = {},
      loading: isRedirectionLinkLoading = false,
    } = {},
  ] = useLazyQuery(GET_RENEW_SUBSCRIPTION_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data?.paymentURL) {
        addError(t("error.failed_fetch_portal_url"));
      } else {
        externalRedirect(paymentUrl, { target: "_blank" });
      }
    },
    onError: (error) => {
      const errorCode = error.graphQLErrors.find(
        (err) => err.extensions?.error_code,
      )?.extensions?.error_code;

      const mappedErrorMessage = rebillyErrorsMapper(errorCode, t);

      if (errorCode === "INVOICE_WAITING") {
        addWarning(mappedErrorMessage);
        return;
      }

      addError(
        mappedErrorMessage ||
          error.message ||
          t("error.failed_fetch_portal_url"),
      );
    },
  });

  const isActiveSubscriptionExpired = useMemo(() => {
    return activeSubscription?.status === "GRACE" || false;
  }, [activeSubscription]);

  const isActiveSubscriptionFree = useMemo(() => {
    return (
      activeSubscription?.subscriptionPlan.toLowerCase().includes("free") ||
      false
    );
  }, [activeSubscription]);

  const handleRenewSubscription = useCallback(async () => {
    if (activeSubscription) {
      try {
        getRenewRedirectionLink();
      } catch (error) {
        addError(t("error.failed_renew_subscription"));
      }
    } else {
      addError(t("error.failed_renew_subscription-alt"));
    }
  }, [activeSubscription, t, addError, getRenewRedirectionLink]);

  useEffect(() => {
    if (invoiceEventData) {
      refetchInvoices();
    }
  }, [invoiceEventData, refetchInvoices]);

  useEffect(() => {
    if (subscriptionEventData) {
      refetchSubscriptions();
    }
  }, [subscriptionEventData, refetchSubscriptions]);

  useEffect(() => {
    if (userSubscriptionEventData) {
      refetchUserData();
    }
  }, [userSubscriptionEventData, refetchUserData]);

  useEffect(() => {
    if (window.location.pathname.startsWith(`/${GUEST_TAB}`)) {
      setIsLoading(false);
      setUser(GUEST_USER);
      setIsGlobalAdmin(false);
      setIsGuest(true);
    } else if (loading) {
      setIsGuest(false);
      setIsLoading(true);
    } else if (error || !data) {
      setIsGuest(false);
      setUser(undefined);
      setIsGlobalAdmin(false);
      setIsLoading(false);
    } else {
      // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { me } = data!;
      setIsGuest(false);
      setUser(me);
      setIsGlobalAdmin(me?.tier === "ADMIN");
      setIsLoading(false);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (user && !isGuest) {
      getCurrentAuthenticatedUser(false).then((cognitoUser: CognitoUser) => {
        setIdentity(cognitoUser.getUsername());
        logEvent("PageLoaded");
      });
    } else {
      setAnonIdentity();
    }
  }, [user, isGuest]);

  useEffect(() => {
    const hasIdentity = async () => {
      setHasIdentity(await hasCognitoIdentity());
    };

    hasIdentity();
  }, []);

  useEffect(() => {
    const crisp = (window as any).$crisp;
    if (user?.firstName || user?.lastName) {
      const name = user.firstName + " " + user.lastName;
      crisp.push(["set", "user:nickname", [name]]);
    }
    if (user?.email) {
      crisp.push(["set", "user:email", [user.email]]);
    }
  }, [user?.firstName, user?.lastName, user?.email]);

  const ctxValue = useMemo(
    () => ({
      user,
      invoices,
      subscriptions,
      subscribedSyndications,
      permissions: user?.permissions || [],
      tierParameters: user?.tierParameters || defaultTierParameters,
      isGuest,
      isLoggedIn: !!user && !isGuest,
      isLoading,
      isGlobalAdmin,
      isTester: user?.tier === "TESTER" || isGlobalAdmin,
      refetch,
      refetchSubscriptions,
      refetchInvoices,
      subscriptionEvent: subscriptionEventData?.productSubscriptionEvents,
      fetchMoreInvoices,
      fetchMoreSubscriptions,
      loginModalState,
      setLoginModalState,
      setHasIdentity,
      activeSubscription,
      isActiveSubscriptionLoading,
      refetchActiveSubscription,
      isActiveSubscriptionExpired,
      isRenewSubscriptionLoading: isRedirectionLinkLoading,
      handleRenewSubscription,
      isActiveSubscriptionFree,
    }),
    [
      user,
      invoices,
      subscriptions,
      subscribedSyndications,
      isGuest,
      isLoading,
      isGlobalAdmin,
      refetch,
      refetchSubscriptions,
      refetchInvoices,
      subscriptionEventData,
      fetchMoreInvoices,
      fetchMoreSubscriptions,
      loginModalState,
      activeSubscription,
      isActiveSubscriptionLoading,
      refetchActiveSubscription,
      isActiveSubscriptionExpired,
      isRedirectionLinkLoading,
      handleRenewSubscription,
      isActiveSubscriptionFree,
    ],
  );

  return (
    <UserContext.Provider value={ctxValue}>{children}</UserContext.Provider>
  );
};
