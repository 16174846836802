import { toZonedTime } from "date-fns-tz";

const tradingViewAvailableTimezones = [
  "Etc/UTC",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Johannesburg",
  "Africa/Lagos",
  "America/Anchorage",
  "America/Argentina/Buenos_Aires",
  "America/Bogota",
  "America/Caracas",
  "America/Chicago",
  "America/El_Salvador",
  "America/Juneau",
  "America/Lima",
  "America/Los_Angeles",
  "America/Mexico_City",
  "America/New_York",
  "America/Phoenix",
  "America/Santiago",
  "America/Sao_Paulo",
  "America/Toronto",
  "America/Vancouver",
  "Asia/Astana",
  "Asia/Ashkhabad",
  "Asia/Bahrain",
  "Asia/Bangkok",
  "Asia/Chongqing",
  "Asia/Colombo",
  "Asia/Dhaka",
  "Asia/Dubai",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Jakarta",
  "Asia/Jerusalem",
  "Asia/Karachi",
  "Asia/Kathmandu",
  "Asia/Kolkata",
  "Asia/Kuala_Lumpur",
  "Asia/Kuwait",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Qatar",
  "Asia/Riyadh",
  "Asia/Yangon",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Tehran",
  "Asia/Tokyo",
  "Atlantic/Azores",
  "Atlantic/Reykjavik",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Copenhagen",
  "Europe/Helsinki",
  "Europe/Istanbul",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Warsaw",
  "Europe/Zurich",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "Pacific/Fakaofo",
  "Pacific/Honolulu",
  "Pacific/Norfolk",
  "US/Mountain",
];

function getTimezoneOffsetMinutes(timeZone: string) {
  const now = new Date();
  const zonedDate = toZonedTime(now, timeZone);
  const utcDate = toZonedTime(now, "UTC");
  return (zonedDate.getTime() - utcDate.getTime()) / 60000;
}

export function getMatchingTimezone(userTimezone: string): string {
  const userRegion = userTimezone.split("/")[0]; // Extract region (e.g., 'Europe')

  const userOffset = getTimezoneOffsetMinutes(userTimezone);

  const sameRegionTimezones = tradingViewAvailableTimezones.filter((tz) =>
    tz.startsWith(userRegion),
  );

  for (const tz of sameRegionTimezones) {
    if (getTimezoneOffsetMinutes(tz) === userOffset) {
      return tz;
    }
  }

  for (const tz of tradingViewAvailableTimezones) {
    if (getTimezoneOffsetMinutes(tz) === userOffset) {
      return tz;
    }
  }

  return "Etc/UTC"; // Default fallback if no match is found
}
